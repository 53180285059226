// Navbar ======================================================================

.navbar {

    .nav-link,
    .navbar-brand {
      text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
      transition: color ease-in-out .2s;
    }
  
    &.bg-primary {
      @include btn-shadow(map-get($theme-colors, "primary"));
    }
  
    &.bg-dark {
      @include btn-shadow(map-get($theme-colors, "secondary"));
    }
  
    &.bg-light {
      @include btn-shadow(map-get($theme-colors, "light"));
  
      .nav-link,
      .navbar-brand {
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1);
      }
  
      .navbar-brand {
        color: $navbar-light-color;
  
        &:hover {
          color: $info;
        }
      }
    }
  }
  
  // Buttons =====================================================================
  
  .btn {
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
  
    &-link {
      text-shadow: none;
    }
  }
  
  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include btn-shadow($value);
    }
  
    .btn-#{$color}:not(.disabled):hover {
      @include btn-shadow(darken($value, 4%));
    }
  }
  
  [class*="btn-outline-"] {
    text-shadow: none;
  }
  
  // Typography ==================================================================
  
  // Tables ======================================================================
  
  // Forms =======================================================================
  
  // Navs ========================================================================
  
  // Indicators ==================================================================
  
  .badge {
  
    &-secondary {
      color: $white;
    }
  }
  
  // Progress bars ===============================================================
  
  // Containers ==================================================================
  
  .card,
  .list-group-item {
    h1, h2, h3, h4, h5, h6 {
      color: inherit;
    }
  }
  