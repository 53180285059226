// // Body
// $body-bg: #f8fafc;

$web-font-path: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap" !default;
@import url($web-font-path);

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// $body-color:               $gray-800;

// // Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;



$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eee;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #999;
$gray-600: #777;
$gray-700: #495057;
$gray-800: #333;
$gray-900: #2d2d2d;
$black: #000;
$blue: #446E9B;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #CD0200;
$orange: #fd7e14;
$yellow: #D47500;
$green: #3CB521;
$teal: #20c997;
$cyan: #3399F3;
$primary: $blue;
$secondary: $gray-500;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;
$body-color: $gray-800;
$headings-color: $gray-900;
$navbar-dark-color: rgba($white,.75);
$navbar-dark-hover-color: $white;

$table-color: $black;
$body-bg: transparent;

$theme-colors: (
    "blue":     $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":     $pink,
    "red":     $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":     $green,
    "teal":     $teal,
    "cyan":     $cyan,
    "black":   $black,
);

@mixin btn-shadow($color){
  @include gradient-y-three-colors(lighten($color, 15%), $color, 50%, darken($color, 4%));
  filter: none;
  border: 1px solid darken($color, 10%);
}