table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    bottom: 12px !important;
}

table.dataTable thead tr th{
    white-space: nowrap;
}

.dataTable tbody tr:hover{
    background-color: #ffecb4;
    cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate{
    float: right;
}

body .container div.dataTables_wrapper div.dataTables_processing {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
}

@media (min-width: 576px){
    .form-inline label {
        justify-content: flex-end;
    }
}