@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.75);
    // visibility: hidden;
    // opacity: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}

.spinner {
    -webkit-animation: rotation 1.5s ease-in-out infinite;
    animation: rotation 1.5s ease-in-out infinite;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    color: $primary;
    display: inline-block;
    width: 3rem;
    height: 3rem;

    &.bigger{
        margin-top: -2rem;
        margin-left: -2rem;
        width: 4rem;
        height: 4rem;
    }

    &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        -webkit-animation: rotation ease-in-out 2s infinite;
        animation: rotation ease-in-out 2s infinite;
    }

    &:after {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
        -webkit-animation: rotation ease-in-out 1s infinite;
        animation: rotation ease-in-out 1s infinite;
    }


}

.spinner,
.spinner:before,
.spinner:after {
    border: 2px solid transparent;
    border-top-color: $primary;
    border-radius: 50%;
}

.spinner.bigger,
.spinner.bigger:before,
.spinner.bigger:after {
    border: 3px solid transparent;
    border-top-color: $primary;
    border-radius: 50%;
}