html {
    background: url(../images/bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body {
    overflow-y: scroll;
}

img{
    max-width: 100%;
}

.sticky{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 100px;
}

.font-1{
    font-size: 1rem;
}

.font-11{
    font-size: 1.1rem;
}

.font-12{
    font-size: 1.2rem;
}

.border-5{
    border-radius: 5px;
}

.bg-white-opt {
    background-color: $white;
}

.btn-block+.btn-block {
    margin-top: 0.2rem;
}

.navbar-brand {
    font-size: 1.5rem;
}

.slogan{
    font-size: calc(0.8rem + 0.2vw);
}

.select2{
    max-width: 100% !important;
}

@media (max-width: 767px){
    .navbar-brand, .slogan {
        width: 100% !important;
    }
    .mobile-button{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1520px;
    }
}