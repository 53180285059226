#second-menu{
    background-color: rgba(0, 0, 0, 0.6);

    .nav-item{

        // &.active, &:active{
        //     background-color: rgba(255, 255, 255, 0.4);
        // }

        &.hover, &:hover{
            background-color: $cyan;
        }

        .nav-link {
            display: block;
            padding: 1rem;
            color: $white;
        }
    }
}

@media (min-width: 768px){
    #second-menu{
        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
}