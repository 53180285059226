.stock-table{
    th, td{
        vertical-align: middle;
    }

    .td-product, .td-sum{
        border: none;
    }

    td table tr{
        .td-product, .td-sum{
            border-bottom: 1px solid #dee2e6;
        }
    }

    td table tr:last-child{
        .td-product, .td-sum{
            border-bottom: none;
        }
    }

    .td-sum{
        width: 20%;
        text-align: right;
    }

    .table-sm td{
        padding: 0;
    }

    td table.table-sm td {
        padding: 5px 0;
    }
}